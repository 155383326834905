.try-container {
  border-radius: 40px;
  padding: 60px 40px 0px 40px !important;
  background: linear-gradient(90deg, var(--primary) -9.04%, var(--blue) 131.5%);
  margin-top: 75px !important;
}

.try-container .try-btn {
  background-color: var(--blue);
  border-radius: 40px;
  font-size: 20px;
  color: white;
}
