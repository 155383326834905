.agenda-container {
    border-radius: 40px;
    padding: 60px 40px 40px 40px !important;
    margin-top: 75px !important;
}

.agenda-webinar-container {
    border-radius: 40px;
}

.agenda-container .agenda-date {
    border-radius: 40px;
}