.reviews-img {
    width: 85px !important;
    height: 85px;
    margin-right: 5px;
    padding: 0px !important;
    border-radius: 1000px;
}

#reviewsCarousel h5 {
    line-height: 20px !important;
}

.reviewslist-content {
    padding-top: 75px;
}

.reviewslist-container {
    border-radius: 40px;
    padding: 60px 40px !important;
}