.home-carousel .main-carousel {
    height: 900px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat;
}

.home-carousel .main-carousel1 {
    background: url(../../../../public/assets/img/carousel/1.jpg);
}

.home-carousel .main-carousel2 {
    background: url(../../../../public/assets/img/carousel/2.jpg);
}

.home-carousel .main-carousel3 {
    background: url(../../../../public/assets/img/carousel/3.jpg);
}

.home-carousel .main-carousel4 {
    background: url(../../../../public/assets/img/carousel/4.jpg);
}

.home-carousel .main-carousel5 {
    background: url(../../../../public/assets/img/carousel/5.jpg);
}

.home-carousel .carousel-caption {
    left: 50px;
    bottom: 100px;
    text-align: left;
    width: 600px;
}

.home-carousel .carousel-indicators {
    margin-top: 50px !important;
    padding-left: 80px;
    padding-right: 80px;
    position: relative;
}

.home-carousel .carousel-indicators .carousel-subIndicator {
    text-indent: 0px !important;
    cursor: pointer;
    opacity: 1;
    width: 18% ;
    margin-left: 1%;
    margin-right: 1%;
}

.home-carousel .carousel-indicators .active {
    font-weight: bold;
}

.carousel-nextslide {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 2;
    display: none;
}

.scrolldown {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 2;
}

@media (max-width: 991px) {
    .home-carousel .main-carousel {
        height: 700px !important;
    }

    .home-carousel .carousel-caption{
        margin-left: 30px !important;
        margin-right: 30px !important;
        left: unset !important;
        right: unset !important;
        width: auto !important;
        bottom: 40px;
    }

    .home-carousel .carousel-indicators .carousel-subIndicator{
        width: auto !important;
        padding: 0px 20px 0px 20px;
        height: auto !important ;
        display: none ;
        text-align: center;
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .home-carousel .carousel-indicators .active{
        display: block !important;
    }

    .home-carousel .carousel-indicators{
        padding: unset !important;
        flex-wrap: wrap  !important;
    }

    .carousel-nextslide {
        display: block;
    }

    .scrolldown {
        display: none;
    }
}

