.efficiency-container {
    border-radius: 40px;
    padding: 60px 40px 60px 40px !important;
    margin-top: 75px !important;
}

.approach-container {
    border-radius: 40px;
    padding: 60px 40px 40px 40px !important;
    margin-top: 75px !important;
    background: linear-gradient(90deg, var(--primary) -9.04%, var(--blue) 131.5%);;
}

.control-container {
    border-radius: 40px;
    padding: 60px 40px !important;
    margin-top: 75px !important;
}

.logging-container {
    border-radius: 40px;
    padding: 60px 40px !important;
    margin-top: 75px !important;
}

.dashboard-container {
    border-radius: 40px;
    padding: 40px 60px !important;
    margin-top: 75px !important;
    background: linear-gradient(90deg, var(--primary) -9.04%, var(--blue) 131.5%);;
}

.offer-container {
    border-radius: 40px;
    padding: 60px 40px !important;
    margin-top: 75px;
}

.security-container {
    border-radius: 40px;
    padding: 60px 40px !important;
    margin-top: 75px !important;
}

.conclusion-container {
    border-radius: 40px;
    padding: 60px 40px !important;
    margin-top: 75px !important;
}