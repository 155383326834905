.login-container {
    border-radius: 40px;
    padding: 60px 40px !important;
    margin-top: 75px !important;
}

.login-container input[type="text"],
.login-container input[type="text"]:focus,
.login-container input[type="text"]:focus-visible,
.login-container input[type="text"]:active,
.login-container input[type="text"]:hover {
    border: none;
    border-bottom: 1px solid var(--gray);
}

.login-container button {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 60px;
}