.contact-container {
    margin-top: 75px !important;
    border-radius: 40px;
    padding: 20px 40px 60px 40px !important;
    display: flex;
}

.contact-container .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-container input[type="text"],
.contact-container input[type="text"]:hover,
.contact-container input[type="text"]:active,
.contact-container input[type="text"]:focus,
.contact-container input[type="text"]:focus-visible {
    border: none;
    border-bottom: 1px solid var(--gray);
}

.contact-container .contact-msg,
.contact-container .contact-msg:hover,
.contact-container .contact-msg:active,
.contact-container .contact-msg:focus,
.contact-container .contact-msg:focus-visible {
    border: none;
    border-bottom: 1px solid var(--gray);
    outline: none;
}