.home-about-container {
    border-radius: 40px;
    padding: 60px 40px !important;
    margin-top: 75px;
}

@media (max-width: 2000px) {
    .home-about-container {
        margin-top: 100px;
    }
}

@media (max-width: 1450px) {
    .home-about-container {
        margin-top: 130px;
    }
}

@media (max-width: 1150px) {
    .home-about-container {
        margin-top: 200px;
    }
}

@media (max-width: 991px) {
    .home-about-container {
        margin-top: 20px;
    }
}