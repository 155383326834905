.tryfree-container {
    border-radius: 40px;
    padding: 60px 40px !important;
    margin-top: 75px;
}

.tryfree-container input[type="text"],
.tryfree-container input[type="text"]:hover,
.tryfree-container input[type="text"]:active,
.tryfree-container input[type="text"]:focus,
.tryfree-container input[type="text"]:focus-visible {
    border: none;
    border-bottom: 1px solid var(--gray);
}

.tryfree-container .submit-btn {
    width: 100%;
    max-width: 320px;
    padding-top: 10px;
    padding-bottom: 10px;
}