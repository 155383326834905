.header-container {
    height: 32px;
    background-color: var(--blue);
    padding-right: 20px;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 1px;
    padding-top: 8px;
}

.header-container .form-select {
    width: 36px;
    height: 18px;
    background-color: transparent;
    border: none;
    color: var(--white);
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
    margin-left: 22px;
    padding: 0px;
    background-image: url(../../../public/assets/img/headerSelect.svg);
    background-size: cover;
}

.header-container .form-select:hover,
.header-container .form-select:active,
.header-container .form-select::after,
.header-container .form-select:focus,
.header-container .form-select:focus-visible {
    box-shadow: none;
}

.navbar {
    height: 50px;
    background-color: var(--primary) !important;
    padding-left: 23px;
}

.navbar .navbar-brand {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}

.navbar .navbar-toggler,
.navbar .navbar-toggler:active,
.navbar .navbar-toggler::before,
.navbar .navbar-toggler:focus {
    margin-top: -5px;
    border: none;
    box-shadow: none;
}

.navbar .nav-item {
    margin-left: 19px;
}

.navbar .nav-item a {
    color: #fff !important;
}

.navbar .navbar-toggler {
    min-width: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

@media screen and (max-width: 1853px) {
    .navbar .nav-item {
        margin-left: 10px;
    }

    .navbar .nav-item a {
        font-size: 13px;
    }
}

@media screen and (max-width: 1540px) {
    .navbar {
        padding-right: 8px;
    }

    .navbar .nav-item {
        margin-left: 1px;
    }

    .navbar .nav-item a {
        font-size: 10px;
    }
}

@media screen and (max-width: 1199px) {
    .navbar-toggler {
        width: auto !important;
        height: auto !important;
        position: unset !important;
        margin-right: 0px !important;
        margin-top: unset !important;
        margin-bottom: unset !important;

    }

    #collapsibleNavbar {
        display: none;
    }

    .navbar .navbar-nav {
        position: absolute;
        background-color: var(--primary);
        top: 70px;
        right: 0px;
        width: 162px;
        z-index: 3;
        padding: 5px 5px 10px 5px;
    }

    .navbar .nav-item a {
        font-size: 15px;
    }
}

.navbar .navbar-nav .navbar-nav-header {
    background-color: var(--blue);
    height: 30px;
    padding-left: 10px;
    position: absolute;
    top: 0px;
    width: 100%;
    display: none;
}

.navbar .navbar-nav .navbar-nav-header .fa {
    font-size: 11px;
    color: var(--white);
    padding-top: 10px;
}

.navbar .navbar-nav .navbar-nav-header .form-select {
    width: 36px;
    height: 18px;
    background-color: transparent;
    border: none;
    color: var(--white);
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 1px;
    margin-left: 22px;
    padding: 0px;
    background-image: url(../../../public/assets/img/headerSelect.svg);
    background-size: cover;
    padding-top: 3px;
    margin-top: 6px;
}

.navbar .navbar-nav .navbar-nav-header .form-select:hover,
.navbar .navbar-nav .navbar-nav-header .form-select:active,
.navbar .navbar-nav .navbar-nav-header .form-select::after,
.navbar .navbar-nav .navbar-nav-header .form-select:focus,
.navbar .navbar-nav .navbar-nav-header .form-select:focus-visible {
    box-shadow: none;
}

.navbar .navbar-nav .navbar-nav-header .form-select option {
    color: var(--primary);
}

.navbar .navbar-toggler,
.navbar .navbar-toggler:active,
.navbar .navbar-toggler::before,
.navbar .navbar-toggler:focus {
    margin-top: -5px;
    border: none;
    box-shadow: none;
}

.navbar-logo {
    width: 200px;
}

.navbar .nav-knowcenter-dropdown li a:hover {
    background-color: var(--primary);
}